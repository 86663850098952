import React from 'react'
import "./style.css"

const ShrinkBtn = ({color,text,onClick}) => {
  return (
    <button className='poppins-30px-regular shrink-btn' style={{backgroundColor:color, color:"#ffffff", borderRadius:"15px", padding:"10px 0",border:"none"}} onClick={onClick}>{text}</button>
  )
}

export default ShrinkBtn

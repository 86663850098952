export const handleDownload = () => {
    // Replace 'url_to_your_pwa' with the actual URL of your PWA
    const url = 'https://mappool-9a59c.firebaseapp.com/';
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'novus_ui.pwa'; // Set the desired filename
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };
import React from 'react'


const styles = {
  
   
    hamburgerMenu:{
      height: "40px",
      width: "45px",
      backgroundColor: "#8E69F0",
      borderRadius: "10px",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      
    },
  
   
    
  }
const HamBurgerMenu = ({onClick,showNavMenu}) => {
  return (
    <div className='display-none-big' style={styles.navBlock}>
            <div style={styles.hamburgerMenu} onClick={onClick}>
           {showNavMenu &&
           <img src='X.svg'></img>
          ||
           <img src='/Menu.svg'></img>
           } 
          </div>
          
    </div>
  )
}

export default HamBurgerMenu

import React from 'react'

const SideNav = ({type, title,href}) => {
  return (
    <div className='display-none-small' style={{width:"100%"}}>
    {type === "left" && <div  style={{height:"70px", width:"100%", borderLeft:"10px solid #8E69F0",padding:"0 20px", display:"flex",alignItems:"center"}}>
      <a href={href}  className='poppins-35px-light' style={{margin:"0 40px", textDecoration:"none", color:"#c1c1c1"}}>{title}</a>
    </div>}
    {type === "right" && <div  style={{height:"70px", width:"100%", borderRight:"10px solid #8E69F0",padding:"0 20px", display:"flex",alignItems:"center", justifyContent:"flex-end"}}>
      <a href={href}  className='poppins-35px-light' style={{margin:"0 40px", textDecoration:"none", color:"#c1c1c1"}}>{title}</a>
    </div>}
    </div>
  )
}

export default SideNav

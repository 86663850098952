import React from 'react'

const Goal = ({title,body}) => {
  return (
    <div className='goal' style={{width:"300px", minHeight:"200px", backgroundColor:"#2D2F3C", borderRadius:"20px", display:"flex", justifyContent:"center", alignItems:"center", padding:"20px", flexDirection:"column", margin:"20px"}}>
        <p className='poppins-25px-bold' style={{color:"#ffffff", textAlign:"center"}}>
            {title}
        </p>
        <p className='poppins-24px-light' style={{color:"#c1c1c1", textAlign:"center"}}>
            {body}
        </p>
    </div>
  )
}

export default Goal

import React from 'react'

const  DownloadButtonNav = ({color,text,onClick}) => {

  
  return (
    <button className='poppins-30px-regular display-none-small' style={{backgroundColor:color, color:"#ffffff", width:"200px",height:"65px", borderRadius:"20px", padding:"10px 0",border:"none"}} onClick={onClick}>{text}</button>
  )
}

export default DownloadButtonNav
import Button1 from "./components/Buttons/Button1";
import ShrinkBtn from "./components/Buttons/ShrinkBtn";
import Goal from "./components/Goals/Goal";
import Input from "./components/Input/Input";
import Nav from "./components/Nav/Nav.js";
import SideNav from "./components/SideNav/SideNav";
import Socials from "./components/Socials";
import { handleDownload } from "./util";

const goals = [
  {
    title:"Reduce transportation cost for event",
    body:"We aim to reduce the amount of money people spend on transportation for event"
  },
  {
    title:"Reduce carbon emission",
    body:"We aim to reduce the amount of cars going to one event, which should  reduce carbon realised in our environment"
  },
  {
    title:"Reduce traffic",
    body:"We aim to reduce the amount of cars going to one event, which would reduce traffic "
  },
  {
    title:"Making event hosting and attending easier",
    body:"Having a platform where event is easy to host and attend is something we dream of"
  },
  {
    title:"Community Building",
    body:"Building communities for your event can be very helpful. At NovusUI we love to see collaboration and community"
  },
  {
    title:"Making Events more fun",
    body:"At NovusUI we want to know that your event is a fun one. We want to make sure you have fun and make new friends"
  }
]
  


function App() {

  
  return (
    <div className="App">
      <div className="section" style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}} >
        <Nav/>
         <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", flex:"1", width:"100%"}}>
            <h1 className="poppins-50px-semibold" style={{color:"#ffffff", textAlign:"center"}}>It is time to handle <span style={{color:"#8E69F0"}}>events </span>the right way </h1>
            
            
            <p className="poppins-24px-light hero-desc">NovusUI events, reinventing how events are being hosted and attended, providing  easier ways to have fun  </p>
            
            <ShrinkBtn color="#2e2f3c" text="Download" onClick={handleDownload}/>
          </div>
          <SideNav title="About" href="#about" type="left"/>
      </div>
      <div className="section" id="about" style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
        <div style={{width:"100%", height:"15vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
          <h1 className="poppins-50px-semibold" style={{color:"#ffffff", textAlign:"center"}}>Our <span style={{color:"#8E69F0"}}>Goals</span></h1>
        </div>
         <div style={{display:"flex", width:"90%", flexWrap:"wrap", justifyContent:"center",flex:"1"}}>
            {
              goals.map(goal=> <Goal title={goal.title} body={goal.body}/>)
            }
         </div>
            
           <div style={{width:"50px", height:"50px", borderRadius:"100%", backgroundColor:"#8E69F0", display:"flex", alignItems:"center", justifyContent:"center"}} onClick={handleDownload}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.75 16.1893L15.4697 13.4697C15.7626 13.1768 16.2374 13.1768 16.5303 13.4697C16.8232 13.7626 16.8232 14.2374 16.5303 14.5303L12.5303 18.5303C12.2374 18.8232 11.7626 18.8232 11.4697 18.5303L7.46967 14.5303C7.17678 14.2374 7.17678 13.7626 7.46967 13.4697C7.76256 13.1768 8.23744 13.1768 8.53033 13.4697L11.25 16.1893V6.5C11.25 6.08579 11.5858 5.75 12 5.75C12.4142 5.75 12.75 6.08579 12.75 6.5V16.1893Z" fill="white"/>
              </svg>
           </div>
           <p className="poppins-30px-regula" style={{margin:"10px 0",color:"#c1c1c1"}}>Download</p>
           <SideNav title="Contact" href="#contact" type="right"/>
      </div>
      <div className="contact" id="contact" style={{padding:"20px 0 40px", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", color:"#ffffff"}}>
            <h1 className="poppins-50px-semibold" style={{textAlign:"center"}}>We would love to hear from you</h1>
            <div style={{margin:"50px 0", display:"flex",flexWrap:"wrap"}}>
              <Input/>
              <ShrinkBtn text="Submit" color="#8E69F0"/>
            </div>
            <div className="socials-div">
              <Socials title="twitter"/>
              <Socials title="insta"/>
              <Socials title="email"/>
            </div>
      </div>
    </div>
  );
}

export default App;
